body{
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    font-variant-numeric: proportional-nums;
    background-color: #fff;
}

a {
    /*transition: .3s color;*/
    text-decoration: none;
}
input {
    font-family: 'Noto Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
}

input::-webkit-contacts-auto-fill-button, input::-webkit-credentials-auto-fill-button
{
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
}


.my-masonry-grid {
    display: flex;
    width: auto;
}
.my-masonry-grid_column {
    background-clip: padding-box;
    width: 395px;
    min-width: 395px;
    margin: 0 24px 12px 0;
}

/* Style your items */
.my-masonry-grid_column > div, .my-masonry-grid_column > button  { /* change div to reference your elements you put in <Masonry> */
    margin-top: 10px;
    margin-bottom: 10px;
}

.my-masonry-grid_config_column {
    background-clip: padding-box;
    width: 290px;
    min-width: 290px;
    margin: 0 24px 12px 0;
}

.my-masonry-grid_config_column > div, .my-masonry-grid_config_column > button {
    margin-top: 10px;
    margin-bottom: 10px;
}


.plot-container > div{
    margin: 0 auto !important;
}

#sidebar{
    height: 100vh;
}

jdiv[class*="chatCopyright"] {
    display: none;
}
jdiv[class*="headerBox"] {
    border-top-right-radius: 10px!important;
}
jdiv[class*="leaf"] {
    display: none;
}
jdiv[class*="leafCont"] {
    display: none;
}
jdiv[class*="logo_"] {
    display: none;
}