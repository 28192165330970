#piechartlegend {
    width: 55%;
    min-height: 240px;
    overflow-y: auto;
    margin-left: 10px;
    height: 240px;
    overflow-x: hidden;
}

.piechartlegendcase {
    display: block;
    min-height: 160px;
    overflow-y: auto;
    margin-left: 10px;
    height: 160px;
    overflow-x: hidden;
    margin-top: 15px;
}

#piechartlegend::-webkit-scrollbar, .piechartlegendcase::-webkit-scrollbar{
    width: 4px;
}

#piechartlegend::-webkit-scrollbar-track, .piechartlegendcase::-webkit-scrollbar-track{
    background: #F5F5F5;
}

#piechartlegend::-webkit-scrollbar-thumb, .piechartlegendcase::-webkit-scrollbar-thumb{
    background: #D2D1D1;
}

#piechartlegend::-webkit-scrollbar-thumb:hover, .piechartlegendcase::-webkit-scrollbar-thumb:hover{
    background: #D2D1D1;
}

/*#piechartlegend #container, .piechartlegendcase{*/
/*    width: 100%;*/
/*}*/

#piechartlegend .legend-item, .piechartlegendcase .legend-item{
    margin: 0;
    cursor: pointer;
    width: 100%;
    border-radius: 6px;
    font-size: 14px;
    padding: 2px;
    align-items: center;
    display: flex;
    justify-content: space-around;
}
#piechartlegend .legend-item:hover, .piechartlegendcase.legend-item:hover{
    background-color: #E1E6EC;
}

#piechartlegend .legend-item .legend-value, .piechartlegendcase .legend-item .legend-value{
    margin-left: 22px;
    display: inline-block;
    width: 150px;
    text-align: right;
    margin-right: 10px;
}
#piechartlegend .legend-item .legend-category{
    display: inline-block;
    width: 460px;
}
#piechartlegend .legend-item .legend-percent{
    color: #7F818D;
    display: inline-block;
    width: 60px;
}

#piechartlegend .legend-item .legend-marker{
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
#piechartlegend .legend-item .legend-text{
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

#piechartlegend .legend-item.disabled .legend-marker{
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}

/**

-----------------------------------------------------------------

 **/

.piechartlegendcase .legend-item .legend-category{
    display: inline-block;
    width: 200px;
}
.piechartlegendcase .legend-item .legend-percent{
    color: #7F818D;
    display: inline-block;
    width: 80px;
}

.piechartlegendcase .legend-item .legend-marker{
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
.piechartlegendcase .legend-item .legend-text{
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

.piechartlegendcase .legend-item.disabled .legend-marker{
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}