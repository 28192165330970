.popup-content {
    margin: auto;
    width: 50%;
}

.popup-content label{
    font-size: 14px;
}

[role=tooltip].popup-content {
    width: max-content;
    border-radius: 12px;
}

.popup-overlay {
    background: rgba(0, 0, 0, .5)
}

[data-popup=tooltip].popup-overlay {
    background: transparent
}

.popup-arrow {
    -webkit-filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
    filter: drop-shadow(0 -3px 3px rgba(0, 0, 0, .16));
    color: #fff;
    stroke-width: 2px;
    stroke: #d7d7d7;
    stroke-dasharray: 30px;
    stroke-dashoffset: -54px;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}