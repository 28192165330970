#insurancechartlegend {
    width: 55%;
    min-height: 260px;
    height: 320px;
    overflow-y: auto;
    display: flex;
    overflow-x: hidden;
    align-items: center;
    justify-content: flex-end;
    padding-right: 100px;
    padding-left: 100px;
}

#insurancechartlegend > div {
    width: 100%;
}

#insurancechartlegend::-webkit-scrollbar {
    width: 4px;
}

#insurancechartlegend::-webkit-scrollbar-track {
    background: #F5F5F5;
}

#insurancechartlegend::-webkit-scrollbar-thumb {
    background: #D2D1D1;
}

#insurancechartlegend::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
}

#insurancechartlegend #container{
    width: 100%;
}

#insurancechartlegend .legend-item {
    margin: 3px;
    cursor: pointer;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;
}
#insurancechartlegend .legend-item:hover {
    background-color: #E1E6EC;
}

#insurancechartlegend .legend-item .legend-category {
    display: inline-block;
    width: 160px;
}
#insurancechartlegend .legend-item .legend-percent {
    color: #7F818D;
    display: inline-block;
    width: 60px;
    margin-left: 10px;
}

#insurancechartlegend .legend-item .legend-marker {
    display: inline-block;
    width: 14px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
#insurancechartlegend .legend-item .legend-left-part {
    display: flex;
    align-items: center;
}
#insurancechartlegend .legend-item .legend-text {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

#insurancechartlegend .legend-item.disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}