#balancepiechartlegend {
    width: 55%;
    min-height: 260px;
    height: 260px;
    overflow-y: auto;
    display: flex;
    align-items: self-start;
    overflow-x: hidden;
}

#balancepiechartlegend::-webkit-scrollbar {
    width: 4px;
}

#balancepiechartlegend::-webkit-scrollbar-track {
    background: #F5F5F5;
}

#balancepiechartlegend::-webkit-scrollbar-thumb {
    background: #D2D1D1;
}

#balancepiechartlegend::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
}

#balancepiechartlegend #container{
    width: 100%;
}

#balancepiechartlegend .legend-item {
    margin: 3px;
    cursor: pointer;
    width: 170px;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
    align-items: center;
    display: flex;
}
#balancepiechartlegend .legend-item:hover {
    background-color: #E1E6EC;
}

#balancepiechartlegend .legend-item .legend-category {
    display: inline-block;
    width: 160px;
}
#balancepiechartlegend .legend-item .legend-percent {
    color: #7F818D;
    display: inline-block;
    width: 60px;
    margin-left: 10px;
}

#balancepiechartlegend .legend-item .legend-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
#balancepiechartlegend .legend-item .legend-text {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

#balancepiechartlegend .legend-item.disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}