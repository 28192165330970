#tacticslegend-left {
    width: 35%;
    min-height: 260px;
    height: 260px;
    overflow-y: auto;
    margin-left: 10px;
    display: flex;
    align-items: center;
}

#tacticslegend-left::-webkit-scrollbar {
    width: 4px;
}

#tacticslegend-left::-webkit-scrollbar-track {
    background: #F5F5F5;
}

#tacticslegend-left::-webkit-scrollbar-thumb {
    background: #D2D1D1;
}

#tacticslegend-left::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
}

#tacticslegend-left #container{
    width: 100%;
}

#tacticslegend-left .legend-item {
    margin: 10px;
    cursor: pointer;
    width: 440px;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
    align-items: center;
    display: flex;
}
#tacticslegend-left .legend-item:hover {
    background-color: #E1E6EC;
}

#tacticslegend-left .legend-item .legend-value {
    margin-left: 22px;
    display: inline-block;
    width: 150px;
    text-align: right;
    margin-right: 10px;
}
#tacticslegend-left .legend-item .legend-category {
    display: inline-block;
    width: 160px;
}
#tacticslegend-left .legend-item .legend-percent {
    color: #7F818D;
    display: inline-block;
    width: 60px;
}

#tacticslegend-left .legend-item .legend-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
#tacticslegend-left .legend-item .legend-text {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

#tacticslegend-left .legend-item.disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}

#tacticslegend-right {
    width: 35%;
    min-height: 260px;
    height: 260px;
    overflow-y: auto;
    margin-left: 10px;
    display: flex;
    align-items: center;
    overflow-x: hidden;
}

#tacticslegend-right::-webkit-scrollbar {
    width: 4px;
}

#tacticslegend-right::-webkit-scrollbar-track {
    background: #F5F5F5;
}

#tacticslegend-right::-webkit-scrollbar-thumb {
    background: #D2D1D1;
}

#tacticslegend-right::-webkit-scrollbar-thumb:hover {
    background: #D2D1D1;
}

#tacticslegend-right #container{
    width: 100%;
}

#tacticslegend-right .legend-item {
    margin: 10px;
    cursor: pointer;
    width: 440px;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
    align-items: center;
    display: flex;
}
#tacticslegend-right .legend-item:hover {
    background-color: #E1E6EC;
}

#tacticslegend-right .legend-item .legend-value {
    margin-left: 22px;
    display: inline-block;
    width: 150px;
    text-align: right;
    margin-right: 10px;
}
#tacticslegend-right .legend-item .legend-category {
    display: inline-block;
    width: 160px;
}
#tacticslegend-right .legend-item .legend-percent {
    color: #7F818D;
    display: inline-block;
    width: 60px;
}

#tacticslegend-right .legend-item .legend-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}
#tacticslegend-right .legend-item .legend-text {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
}

#tacticslegend-right .legend-item.disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}

.graph {
    opacity: 0;
    animation-name: animateIn;
    animation-duration: 1s;
    animation-fill-mode: forwards;
}

@keyframes animateIn {
    0% {
        opacity: 0;
        transform: scale(0.6) rotate(-15deg);
    }
    100% {
        opacity: 1;
        transform: scale(1) rotate(0);
    }
}

/*.class{*/
    /*color: #41AFFF;*/
    /*color: #a0d7ff;*/
/*}*/

#tacticsroundlegend {
    width: 55%;
    min-height: 260px;
    margin-left: 10px;
    height: 498px;
    overflow: auto;
    /* padding-right: 30px; */
    margin-right: 40px;
    align-items: center;
    display: flex;
}

#tacticsroundlegend::-webkit-scrollbar {
     width: 8px;
     background-color: #F5F6FB;
 }

#tacticsroundlegend::-webkit-scrollbar-thumb {
     background-color: rgba(10,10,10,0.2);
 }

#tacticsroundlegend > div {
    width: 80%;
}

#tacticsroundlegend .legend-item {
    margin: 10px;
    cursor: pointer;
    width: 440px;
    border-radius: 6px;
    font-size: 14px;
    padding: 4px;
}
#tacticsroundlegend .legend-item:hover {
    /*background-color: #E1E6EC;*/
}

#tacticsroundlegend .legend-item .legend-value {
    margin-left: 22px;
    display: inline-block;
    width: 150px;
    text-align: right;
    margin-right: 10px;
}
#tacticsroundlegend .legend-item .legend-category {
    display: inline-block;
    width: 160px;
}
#tacticsroundlegend .legend-item .legend-percent {
    color: #7F818D;
    display: inline-block;
    width: 60px;
}

#tacticsroundlegend .legend-marker {
    display: inline-block;
    width: 8px;
    height: 8px;
    margin-right: 10px;
    margin-left: 6px;
    border-radius: 15px;
    flex-basis: 8px;
}
#tacticsroundlegend .legend-text {
    display: inline-block;
    flex-basis: 40%;
}
#tacticsroundlegend .legend-right {
    flex: 1;
    text-align: right;
    padding-right: 20px;
}
#tacticsroundlegend .legend-right .legend-overspend {
    color: #EF5E70;
    margin-right: 20px;
}
#tacticsroundlegend .legend-right .legend-spend {
    color: #000;
    text-align: right;
}

#tacticsroundlegend .disabled .legend-marker {
    opacity: 0.5;
    background: #ddd;
    display: inline-block;
}


:root {
    --progress-width: 600px;
}

#tacticsroundlegend .progress {
    margin: 3px 0;
    position: relative;
    border: 0;
    width: var(--progress-width, 500px);
    height: 34px;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    flex: 1;
    cursor: pointer;
}
#tacticsroundlegend .percent {
    flex-basis: 80px;
    font-size: 14px;
    font-weight: 500;
    line-height: 34px;
    margin: 3px;
    height: 34px;
}
#tacticsroundlegend .progress .progress-text {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    color: #000;
    line-height: 34px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 500;
    height: 34px;
    width: 100%;

}
#tacticsroundlegend .progress .progress-bar {
    height: 100%;
    overflow: hidden;
    background-color: #EFF2F5;
    border-radius: inherit;
    overflow: hidden;
    position: absolute;
}
#tacticsroundlegend .progress .progress-bar-red {
    height: 100%;
    overflow: hidden;
    /*background-color: #EFD4DA;*/
    background-color: #EF5E70;
    opacity: 0.2;
    border-radius: inherit;
    overflow: hidden;
    position: absolute;
    right: 0;
}
#tacticsroundlegend .progress .progress-bar .progress-text, #tacticsroundlegend .progress .progress-bar-red .progress-text {
    color: #000;
}
